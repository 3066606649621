export const environment = {
    production: true,
    env: 'development',

    // ws: window.location.protocol + '//' + window.location.hostname + '/api/',
    host: 'https://dev.apicuron.org',
    ws: 'https://dev.apicuron.org/api',
    auth_ws: 'https://dev.apicuron.org/api',
    public_file_url: 'https://dev.apicuron.org/api/files',
    resource_logo_path: 'resources',
    orcid_url: 'https://pub.sandbox.orcid.org/v3.0/',
    orcid_main_url: 'https://sandbox.orcid.org/'
  };
  